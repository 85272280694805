import React, { useState, useEffect } from "react";
import { useParams, useLocation } from 'react-router-dom';
import Swiper from "../component/swiper/swiper";
import { useTranslation } from 'react-i18next';


export default function Read() {
    const { id } = useParams();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const [carouselData, setCarouselData] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
    const [activeSlide, setActiveSlide] = useState(0);
    const [isLastItem, setIsLastItem] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const pushAd = () => {
            try {
                const adsbygoogle = window.adsbygoogle

                adsbygoogle.push({})
            } catch (e) {
                console.error(e)
            }
        }

        let interval = setInterval(() => {
            // Check if Adsense script is loaded every 300ms
            if (window.adsbygoogle) {
                pushAd()
                // clear the interval once the ad is pushed so that function isn't called indefinitely
                clearInterval(interval)
            }
        }, 300)

        return () => {
            clearInterval(interval)
        }
    }, [])

    useEffect(() => {

        if (id) {
            fetchData(id, selectedLanguage);
            setActiveSlide(0);
        }

    }, [id, selectedLanguage, location.search]);

    useEffect(() => {
        if (carouselData && activeSlide === carouselData.length - 1) {
            setIsLastItem(true);
        } else {
            setIsLastItem(false);
        }
    }, [activeSlide, carouselData]);

    useEffect(() => {
        setSelectedLanguage(i18n.language);
    }, [i18n.language]);

    const mergeWithAds = async (articles, ads1) => {
        let ads = ads1
        if(ads > 2){
            ads = ads1.slice(0, 2);
        }
        if(articles.length != 0){
          
            const result = [];
            let adIndex = 0;
            let articleIndex = 0;
    
            function getRandomNumber(min, max) {
                return Math.floor(Math.random() * (max - min + 1)) + min;
            }
    
            const firstAdPosition = getRandomNumber(6, 10);
            const minGap = 5;
    
            while (articleIndex < articles.length && articleIndex < firstAdPosition) {
                result.push(articles[articleIndex++]);
            }
            if (adIndex < ads.length) {
                if(articles.length > 5){
                    result.push(ads[adIndex++]);
                }
            }
    
            while (articleIndex < articles.length) {
                if (adIndex < ads.length && articleIndex >= firstAdPosition + minGap) {
                    const secondAdPosition = getRandomNumber(firstAdPosition + minGap, articles.length);
  
                    if(articles.length > 10){
                        if (articleIndex >= secondAdPosition) {
                            result.push(ads[adIndex++]);
                        }
                    }
                  
                }
                result.push(articles[articleIndex++]);
            }
            // while (adIndex < ads.length) {
            //     result.push(ads[adIndex++]);
            //     // if(ads.length == adIndex){
            //     //     adIndex = 0
            //     // }
            // }

            if(articles.length == 1){
                result.push({default:false})
            }


            let reverseData = result.reverse();
            reverseData.push({default:true})
            setCarouselData(reverseData);
        }else{
            setCarouselData([{}])
        }
       
    }

    const fetchData = async (id, language) => {

        let url =``;
        if(id === 'featured' || id === 'trending' || id === 'latest'){
            url =`http://64.227.146.52:90/wp-json/api/${id}?lang=${language}&page_no=1`
        }else{

            let category_id = id.split('-');
            url =`http://64.227.146.52:90/wp-json/api/category?lang=${language}&page_no=1&category_id=${category_id[1]}`
        }
        
        try {
            if (url) {
                const response = await fetch(url);
                const adsresponse = await fetch(`http://64.227.146.52:90/wp-json/api/ads_list?lang=${language}&page_no=1`)
                if (!response.ok) {
                    throw new Error('Network response was not ok for the primary API');
                }
                if (!adsresponse.ok) {
                    throw new Error('Network response was not ok for the ads API');
                }

                const result = await response.json().catch(error => {
                    throw new Error('Error parsing JSON for the primary API response: ' + error.message);
                });
                const adsResult = await adsresponse.json().catch(error => {
                    throw new Error('Error parsing JSON for the ads API response: ' + error.message);
                });
                if(result.results){
                    mergeWithAds(result.results, adsResult.results)
                }else{
                    setCarouselData([{}]);
                }
                
            } else {
                setCarouselData([]);
            }
        } catch (error) {
            console.log("Error fetching data:", error);
        }
    };

    const handleNextSlide = () => {
        if (!isLastItem) {
            setActiveSlide(activeSlide + 1);
        }
    };

    const emptyText = () => {
        return (
            <div className="row">
                <div className="col-12" style={{paddingLeft:20,marginTop:50,fontSize:24,fontWeight:'bold'}}>No Data</div>
            </div>
        );
    }

    return (
        <>
            {isMobile ? (
                <>
                    {carouselData ? (
                        carouselData.length !== 1 ? (
                            <Swiper
                                data={carouselData}
                                activeSlide={activeSlide}
                                onRequestChange={setActiveSlide}
                                forwardBtnProps={{
                                    disabled: isLastItem,
                                    onClick: handleNextSlide
                                }}
                                id={id}
                            />
                        ) : (
                            'Empty Data'
                        )
                    ) : (
                        <div>Loading...</div>
                    )}
                </>
            ) : (
                <>
                    {carouselData ? (
                        carouselData.length !== 1 ? (
                            <Swiper
                                data={carouselData}
                                activeSlide={activeSlide}
                                onRequestChange={setActiveSlide}
                                forwardBtnProps={{
                                    disabled: isLastItem,
                                    onClick: handleNextSlide
                                }}
                                id={id}
                            />
                        ) : (
                            emptyText()
                        )
                    ) : (
                        <div>Loading...</div>
                    )}
                </>
            )}
        </>
    );
}
