import React, { useState, useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import MyNavbar from './navbar';
import Sidebar from './sidebar';
import adsRight from '../assets/ads/adsRight.gif';
import adsLeft from '../assets/ads/adsLeft.gif';
import { Button, ButtonGroup } from 'react-bootstrap';

function Layout({ handleChange, i18n }) {
  const navigate = useNavigate();
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const searchRef = useRef(null); // Ref to the search container
  const buttonRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // Toggle search visibility
  const toggleSearch = () => {
    setIsSearchVisible(prevState => !prevState);
  };

  useEffect(() => {
    
    const handleClickOutside = (event) => {
      if (
        searchRef.current && 
        buttonRef.current &&
        !searchRef.current.contains(event.target) && 
        !buttonRef.current.contains(event.target)
      ) {
        setIsSearchVisible(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchRef, buttonRef]);



  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const searchData = () => {
    if (searchTerm.trim()) {
      navigate(`/search/${searchTerm}`);
      setIsSearchVisible(false); // Hide search after performing search
    }
  };



  return (
    <div className="layout">
      {isSidebarVisible && (
        <Sidebar
          handleChange={handleChange}
          i18n={i18n}
          closeSidebar={toggleSidebar}
        />
      )}
      <MyNavbar
        handleChange={handleChange}
        i18n={i18n}
        toggleSidebar={toggleSidebar}
        toggleSearch={toggleSearch}
        isSearchVisible={isSearchVisible}
        buttonRef={buttonRef} 
      />

      <div style={{ width: '100vw', overflow: 'hidden' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'stretch' }}>
          <div className={isMobile ? 'displayNone' : 'displayShow'} style={{ flex: '0 0 calc(6vh + 6vw)', border: '1px solid #e8e8e8', marginLeft: '-1px' }}>
            <img src={adsLeft} width={'100%'} height={'100%'} alt="Left Ad" />
          </div>
          <div className='main-container' style={{ flex: '1', overflow: 'hidden' }}>
            {isSearchVisible && (
              <div ref={searchRef} style={{ position: 'absolute', zIndex: 1000, textAlign: 'end', right: '9vw', display: 'flex', alignItems: 'center' }}>
                <ButtonGroup aria-label="Basic example" style={{ display: 'flex', alignItems: 'center', background: '#fff', padding: '0px 0px 0px 20px', borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderLeft: '1px solid #595959', borderBottom: '1px solid #595959', borderRight: '1px solid #595959' }}>
                  <i className="fa fa-search"></i>
                  <input style={{ borderTopLeftRadius: 5, borderBottomLeftRadius: 5, border: '1px solid #e8e8e8', height: 64, width: '34vw', border: 'none', padding: '0px 20px', fontSize: 18, outline: 'none' }}
                    placeholder='search .. '
                    value={searchTerm}
                    onChange={handleSearchChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        searchData();
                      }
                    }}
                  />
                  <Button variant="secondary" onClick={searchData} style={{ height: 64, width: 100, borderBottomRightRadius: 18, fontSize: 16, fontWeight: 600, background: '#00D172', border: 'none' }}>Search</Button>
                </ButtonGroup>
              </div>
            )}

            <Outlet />
          </div>
          <div className={isMobile ? 'displayNone' : 'displayShow'} style={{ flex: '0 0 calc(6vh + 6vw)', border: '1px solid #e8e8e8', marginRight: '-1px' }}>
            <img src={adsRight} width={'100%'} height={'100%'} alt="Right Ad" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Layout;
