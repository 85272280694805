import React, { useState, useEffect } from "react";
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Card from 'react-bootstrap/Card';
import Pagination from 'react-bootstrap/Pagination';

export default function Search() {
    const { id } = useParams();
    const { t, i18n } = useTranslation();
    const [searchData, setSearchData] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
    const [activePage, setActivePage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); // Default to 1 page

    useEffect(() => {
        setSelectedLanguage(i18n.language);
    }, [i18n.language]);

    const fetchData = async (id, language, page_no = 1) => {
        let url = `http://64.227.146.52:90/wp-json/api/search?lang=${language}&page_no=${page_no}&text=${id}`;
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Network response was not ok for the primary API');
            }
            const result = await response.json();
            setSearchData(result.results);
            setTotalPages(result.total_page || 1); // Set total pages, defaulting to 1 if not provided
        } catch (error) {
            console.log("Error fetching data:", error);
            setSearchData([]); // Set to an empty array in case of error
        }
    };

    const handlePageClick = (pageNumber) => {
        setActivePage(pageNumber);
        fetchData(id, selectedLanguage, pageNumber); // Fetch the new page
    };

    const handlePrevClick = () => {
        if (activePage > 1) {
            setActivePage(prev => prev - 1);
            fetchData(id, selectedLanguage, activePage - 1);
        }
    };

    const handleNextClick = () => {
        if (activePage < totalPages) {
            setActivePage(prev => prev + 1);
            fetchData(id, selectedLanguage, activePage + 1);
        }
    };

    const renderPagination = () => {
        const startPage = Math.max(activePage - 1, 1); // Show one before and after the current page
        const endPage = Math.min(startPage + 2, totalPages); // Show at most 3 items

        let items = [];

        // Display first page if not in the range
        if (startPage > 1) {
            items.push(
                <Pagination.Item key={1} active={activePage === 1} onClick={() => handlePageClick(1)}>
                    {1}
                </Pagination.Item>
            );

            // Show ellipsis if there's a gap between the first page and the start page
            if (startPage > 2) {
                items.push(<Pagination.Ellipsis key="start-ellipsis" />);
            }
        }

        // Display middle pages
        for (let number = startPage; number <= endPage; number++) {
            items.push(
                <Pagination.Item key={number} active={number === activePage} onClick={() => handlePageClick(number)}>
                    {number}
                </Pagination.Item>
            );
        }

        // Show ellipsis if there's a gap between the end page and the last page
        if (endPage < totalPages - 1) {
            items.push(<Pagination.Ellipsis key="end-ellipsis" />);
        }

        // Display last page if not in the range
        if (endPage < totalPages) {
            items.push(
                <Pagination.Item key={totalPages} active={activePage === totalPages} onClick={() => handlePageClick(totalPages)}>
                    {totalPages}
                </Pagination.Item>
            );
        }

        return (
            <Pagination>
                <Pagination.Prev onClick={handlePrevClick} disabled={activePage === 1} />
                {items}
                <Pagination.Next onClick={handleNextClick} disabled={activePage === totalPages} />
            </Pagination>
        );
    };

    useEffect(() => {
        // Reset active page to 1 whenever the id changes
        setActivePage(1);
        fetchData(id, selectedLanguage, 1); // Fetch data for the first page
    }, [id, selectedLanguage]);

    return (
        <div className="searchTab">
            <div className="row">
                <div className="col-md-12 result">
                    Showing results for <span className="header">{id}</span>
                </div>
            </div>
            {searchData ? searchData.length !== 0 ? (
                <>
                    <div className="row main-box">
                        {searchData.map((item, index) => {
                            const imageUrl = item.media_url || "fallback_image_url_here";
                            return (
                                <div key={index} className="col-md-4" style={{ padding: 'calc(0.5vh + 0.5vw)',paddingTop:'calc(0.2vh + 0.2vw)' }}>
                                    <Link to={`/news/${selectedLanguage}/search-${item.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Card >
                                            <Card.Img variant="top"  src={imageUrl} />
                                            <Card.Body>
                                                <Card.Title>{item.title}</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </div>
                            );
                        })}
                    </div>
                    <div className="row">
                        <div className="col-md-12" style={{ display: "flex", textAlign: "center", justifyItems: 'center', justifyContent: "center" }}>
                            {renderPagination()}
                        </div>
                    </div>
                </>
            ) : <><div>No Data</div></> : (
                <div>Loading...</div>
            )}
        </div>
    );
}
