import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'hn','fr','bl'],
    fallbackLng: 'en',
    detection: {
      order: ['cookie', 'localStorage', 'htmlTag', 'path', 'subdomain'],
      caches: ['cookie']
    },
    backend: {
      loadPath: `../../jsonFile/{{lng}}/translation.json`,
      request: (options, url, payload, callback) => {
        fetch(url, options)
          .then(async response => {
            const data = await response.json();
            callback(null, { status: response.status, data });
          })
          .catch(err => {
            console.error(`Failed to load translation file from: ${url}`, err);
            callback(err, { status: 500 });
          });
      }
    }
  });

export default i18n;
