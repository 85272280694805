import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation,Navigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './App.css';
import Read from './read/read';
import News from './news/news';
import Search from './search/search'
import './langauge/i18n'; // Corrected path to 'language/i18n'
import Layout from './layout/layout';
import NotFound from './notFound/notFound'; 
function App() {
  const { i18n } = useTranslation();
  const handleChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <div className="App">
       <Router>
        <Routes>
          <Route path="/" element={<Layout handleChange={handleChange} i18n={i18n} />}>
            <Route path="/read/:id" element={<Read />} />
            <Route path="/news/:language/:id" element={<News />} />
            <Route path="/news" element={<Navigate to="/news/en/default" />} />
            <Route path="/search/:id" element={<Search />} />
            <Route path="/" element={<Navigate to="/read/latest" />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}



export default App;
