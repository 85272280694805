import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { FaPlay, FaPause, FaStop, FaPlayCircle } from 'react-icons/fa';
import '../../App.css';
import { EffectCoverflow, Navigation } from 'swiper/modules';
import pageFold from '../../assets/music/pageturn.mp3'
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { useTranslation } from 'react-i18next';
import defaultImage from '../../assets/ads/adsLeft.gif';
import play from '../../assets/card/play.svg'
import pause from '../../assets/card/pause.svg'
import stop from '../../assets/card/stop.svg'
import { setRef } from '@mui/material';

export default function Swipers({ data, id }) {
  const swiperRef1 = useRef(null);
  let currentPage = 1;
  const activeCardIndexRef = useRef(data.length - 1);
  const [storeData, setStoreData] = useState(data.length);
  const { t, i18n } = useTranslation();
  const [swiperRef, setSwiperRef] = useState(null);
  const [activeHeader, setActiveHeader] = useState('');
  const [activeDescription, setActiveDescription] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [isSetTime, setIsSetTime] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [currentUtterance, setCurrentUtterance] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio] = useState(new Audio(pageFold));
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const timeoutIdRef = useRef(null);
  const [spaceBetween, setSpaceBetween] = useState(90); // Default value
  const [os, setOS] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const utteranceRef = useRef(new SpeechSynthesisUtterance());
  const location = useLocation();

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    if (userAgent.indexOf('win') !== -1) {
      setOS('Windows');
    } else if (userAgent.indexOf('mac') !== -1) {
      setOS('MacOS');
    } else if (userAgent.indexOf('linux') !== -1) {
      setOS('Linux');
    } else {
      setOS('Unknown');
    }
  }, []);
  useEffect(() => {
    return () => {
      speechSynthesis.cancel();
    };
  }, []);

  useEffect(() => {
    const updateSpaceBetween = () => {
      const vh = window.innerHeight * 0.04;
      const vw = window.innerWidth * 0.05;
      const calculatedSpaceBetween = vh + vw;
      setSpaceBetween(calculatedSpaceBetween);
    };

    updateSpaceBetween(); // Initial call
    window.addEventListener('resize', updateSpaceBetween);

    return () => {
      window.removeEventListener('resize', updateSpaceBetween);
    };
  }, []);
  useEffect(() => {
    if (isPlaying) {
      stopSpeech();
    }
    return () => {
    };
  }, [location]);

  useEffect(() => {
    setSelectedLanguage(i18n.language);
    if (isPlaying) {
      stopSpeech();
    }

  }, [i18n.language]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowLeft') {
        console.log(swiperRef1)
        const nextButton = document.querySelector('.swiper-button-next');
        if (nextButton) {
          console.log(swiperRef1.current.slides.length - 1, activeCardIndexRef.current)
          if ((swiperRef1.current.slides.length - 1) == activeCardIndexRef.current) {

          } else {
            nextButton.click();
          }
          // Trigger click on next button
        }
      } else if (event.key === 'ArrowRight') {
        const prevButton = document.querySelector('.swiper-button-prev');
        if (prevButton) {
          prevButton.click(); // Trigger click on prev button
        }

      }
    };


    document.addEventListener('keydown', handleKeyDown);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (swiperRef && data && data.length > 0) {
      swiperRef.slideTo(data.length - 2, 0);
    }
  }, [swiperRef, data]);


  useEffect(() => {
    setStoreData(data.length);
  }, data)
  const startSpeech = (index) => {
    console.log('speake 1')
    const text = ReadText(data[index]);
    setIsPlaying(true)
    speakAllTexts(text, index);
  };

  const speakAllTexts = async (texts, index) => {
    console.log('speake 2', currentIndex, texts.length)
    setIsSpeaking(true);
    setIsPaused(false);
    setIsPlaying(true);
    for (let i = 0; i < texts.length; i++) {
      setCurrentIndex(i);
      await speakText(texts[i]);
    }

    if (index < data.length) {
      stopSpeech();
      swiperRef.slidePrev();
    }
  };
  const handleSwiper = (swiper) => {
    console.log('handleSwiper', swiper)
    swiperRef.current = swiper;
  };


  const startTimeout = (index) => {
    console.log('speake 4')
    timeoutIdRef.current = setTimeout(() => {
      startSpeech(index - 1);
      setIsSetTime(false)
    }, 3500);
  };



  const speakText = (text) => {
    return new Promise((resolve) => {
      const utterance = utteranceRef.current;
      utterance.lang = selectedLanguage == 'hn' ? 'hi-IN' : selectedLanguage == 'en' ? 'en-Us' : 'fr-FR';
      const voices = speechSynthesis.getVoices();
      const selectedVoice = voices.find(voice =>
        selectedLanguage == 'hn' ? voice.name.includes('Lekha') : selectedLanguage == 'en' ? voice.name.includes('Rishi') : selectedLanguage == 'fr' ? voice.name.includes('Daniel (French (France))') : voice.name.includes('Lekha')
      );
      if (selectedVoice) {
        utterance.voice = selectedVoice;
      }
      utterance.text = text;
      utterance.onend = resolve; // Resolve the promise when the speech ends
      speechSynthesis.speak(utterance);
    });
  };

  const pauseSpeech = () => {
    if (isSpeaking) {
      speechSynthesis.pause();
      setIsPaused(true);
    }
  };

  const resumeSpeech = () => {
    console.log('enter here')
    if (selectedLanguage != 'en') {
      speechSynthesis.resume();
      setIsPaused(false);
    } else {
      if (speechSynthesis.paused) {
        speechSynthesis.resume();
        setIsPaused(false);
      }
    }

  };

  const stopSpeech = () => {
    if (isPlaying) {
      speechSynthesis.cancel();
      setCurrentIndex(0);
      setIsPaused(false);
      setCurrentUtterance(null);
      setIsPlaying(false);
    }

  };


  const stopTimeout = () => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    }
  };

  const ReadText = (item) => {
    if (!item || !item.bullet_points) return [];
    let array = [];
    array.push(item.title);
    if (item.ad_type != "Full Scren Media") {
      item.bullet_points.forEach((event) => {
        let bulletText = Object.values(event)[0];
        array.push(bulletText)
      });
      return array;
    } else {
      return []
    }
  };

  const mergeWithAds = async (articles, ads) => {
    const result = [];
    let adIndex = 0;
    let articleIndex = 0;

    function getRandomNumber(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const firstAdPosition = getRandomNumber(6, 10);
    const minGap = 5;

    while (articleIndex < articles.length && articleIndex < firstAdPosition) {
      // console.log(articleIndex)
      result.push(articles[articleIndex++]);
    }
    if (adIndex < ads.length) {
      result.push(ads[adIndex++]);
    }

    while (articleIndex < articles.length) {
      if (adIndex < ads.length && articleIndex >= firstAdPosition + minGap) {
        const secondAdPosition = getRandomNumber(firstAdPosition + minGap, articles.length);
        // console.log(firstAdPosition + minGap, secondAdPosition)
        if (articleIndex >= secondAdPosition) {
          result.push(ads[adIndex++]);
        }
      }
      result.push(articles[articleIndex++]);
    }

    while (adIndex < ads.length) {
      result.push(ads[adIndex++]);
    }
    let reverseData = result.reverse();
    data = [...data, ...reverseData]

  }
  const fetchData = async (id, language, currentPage) => {
    let url = `http://64.227.146.52:90/wp-json/api/${id}?lang=${language}&page_no=${currentPage}`
    try {
      if (url) {
        const response = await fetch(url);
        const adsresponse = await fetch(`http://64.227.146.52:90/wp-json/api/ads_list?lang=${language}&page_no=1`);

        if (!response.ok) {
          throw new Error('Network response was not ok for the primary API');
        }
        if (!adsresponse.ok) {
          throw new Error('Network response was not ok for the ads API');
        }

        const result = await response.json().catch(error => {
          throw new Error('Error parsing JSON for the primary API response: ' + error.message);
        });
        const adsResult = await adsresponse.json().catch(error => {
          throw new Error('Error parsing JSON for the ads API response: ' + error.message);
        });
        if (result.results) {
          mergeWithAds(result.results, adsResult.results)
        } else {

        }

      }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const replaceVowelsWithHyphens = (input) => {
    const vowels = 'aeiouAEIOUआइईउऊऋएऐओऔअािीुूेैोौंः ';
    let result = '';

    for (let char of input) {
      if (vowels.includes(char)) {
        result += '-';
      } else {
        result += char;
      }
    }

    return result;
  };
  const handleSlideChange = (swiper) => {
    swiperRef1.current = swiper;
    activeCardIndexRef.current = swiper.activeIndex + 1;
    const activeIndex = swiper.activeIndex + 1;
    const currentItem = data[activeIndex - 1];
    setActiveHeader(currentItem.title ? currentItem.title : '');
    setActiveDescription(currentItem.bullet_points ? currentItem.bullet_points : [])
    setIsNextDisabled(activeIndex === data.length - 1);

    if (isPlaying) {
      console.log('speake 4')
      setCurrentIndex(0);
      stopSpeech();
    }
    if (isSetTime) {
      stopTimeout();
      if (!isPaused) {
        console.log('isplay')
        startSpeech(activeIndex - 1);
      }

    }

    if (((currentItem.type_of_article == 'Original' || currentItem.type_of_article == 'Translated') && !currentItem.type) || (currentItem.type_of_ad && currentItem.ad_type == 'ads artical')) {
      setTimeout(() => {
        if (isPlaying) {
          if (isPaused) {
            resumeSpeech();
          } else {
            stopSpeech();
            startSpeech(activeIndex - 1);
          }

        }

        if (currentItem.urlLink) {
          // console.log(currentItem.urlLink)
          let newUrl = '';
          if (selectedLanguage == 'hn') {
            newUrl = `/news/` + selectedLanguage + '/' + replaceVowelsWithHyphens(currentItem.title) + '-' + currentItem.id;
          } else {
            newUrl = `/news` + (currentItem.urlLink).split('/news')[1];
          }
          // console.log(newUrl)
          window.history.pushState(null, null, newUrl);
        }
      }, 1000);
    } else {
      if (isPlaying) {
        stopSpeech();
        setIsSetTime(true);
        startTimeout(activeIndex);
      }
    }

    if (activeIndex == 3) {
      currentPage++
      // fetchData(id, selectedLanguage, currentPage);
    }

  };



  const formatTimeAgo = (timestamp) => {
    const now = new Date();
    const timeDifference = now - new Date(timestamp);

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return days === 1 ? '1 day ago' : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
    } else {
      return seconds === 1 ? '1 second ago' : `${seconds} seconds ago`;
    }
  };
  const getHeaderFontSize = (title) => {
    return isMobile ? '16px !important' : (title.length >= 0 && title.length < 42) ? 'calc(0.7vw + 0.8vh) !important' :
      (title.length >= 42 && title.length < 84) ? 'calc(0.7vw + 0.7vh) !important' : 'calc(0.6vw + 0.6vh) !important';
  };
  const getHeaderFontSizeMobile = (title) => {
    return isMobile ? '16px !important' : (title.length >= 0 && title.length < 42) ? 'calc(0.7vw + 0.8vh) !important' :
      (title.length >= 42 && title.length < 84) ? 'calc(0.7vw + 0.7vh) !important' : 'calc(0.6vw + 0.6vh) !important';
  };

  const getDescriptionFontSize = (data) => {
    const lengths = data.map(item => {
      const key = Object.keys(item)[0];
      return item[key] ? item[key].length : 0;
    });
    const maxLength = Math.max(...lengths);
    return (maxLength >= 0 && maxLength < 50) ? 'calc(0.7vw + 0.6vh) !important' :
      (maxLength >= 50 && maxLength < 100) ? 'calc(0.6vw + 0.6vh) !important' : 'calc(0.55vw + 0.5vh) !important';
  };



  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }
  const cardDesign = (item, index) => {
    // console.log(item)
    return <>
      {item.default == false ? <>
        <div className="row cardDesign">
        </div>
      </> : <>
        <div className="row cardDesign">
          <div className="col-md-12 col-sm-12 image" style={{ padding: '0px' }}>
            {item.media_url ? <img src={item.media_url} width={"100%"} style={{ height: 'calc(7vw + 7vh)' }} alt={item.title} /> : ''}
          </div>
          <div className="col-md-12 col-sm-12 header" style={{ textAlign: 'left', lineHeight: 'initial', fontSize: 'calc(0.6vw + 0.6vh)', fontWeight: '500', padding: `5px calc(1vw + 0.8vh)`, minHeight: 'calc(3vh + 3vw) !important', maxHeight: 'calc(3vh + 3vw) !important', display: 'flex', alignItems: 'center' }}>
            {item.title}
          </div>
          <div className="col-md-12 col-sm-12 description" style={{ background: '#F8F9FC', display: 'flex', alignItems: 'center', minHeight: 'calc(12vw + 12vh)', maxHeight: 'calc(12vw + 12vh)' }}>
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: '0px',
                  minHeight: '50px'
                },
              }}
              variant={'outlined'}
            >
              {item.bullet_points && item.bullet_points.map((bulletPoint, eventIndex) => {
                const isLastItem = eventIndex === item.bullet_points.length - 1;
                const bulletText = Object.values(bulletPoint)[0];
                return (
                  <TimelineItem key={eventIndex}>
                    <TimelineSeparator>
                      <TimelineDot />
                      {!isLastItem && (
                        <TimelineConnector variant="outlined" />
                      )}
                    </TimelineSeparator>
                    <TimelineContent>{bulletText}</TimelineContent>
                  </TimelineItem>
                );
              })}
            </Timeline>
          </div>
          <div className="col-md-12 col-sm-12" style={{ marginBottom: 'calc(0.1vh + 0.1vw)', marginTop: 'calc(0.3vh + 0.3vw)' }}>
            {item.media_url ? (
              <div className='row'>
                <div className="col-md-7 col-sm-7 col-7" style={{ marginBottom: 'calc(0.3vh + 0.3vw) !important' }}>
                  <div className='row'>
                    {item.ad_type == "Article Format" ? <>
                      <div className="col-md-12 col-sm-12" style={{ marginBottom: 'calc(0.3vh + 0.3vw) !important', textAlign: 'left', fontSize: 'calc(0.5vw + 0.5vh)', display: 'flex' }}>
                        <span><a href={item.source_url} target="_blank" title={item.type_of_ad} className='ellipsis' rel="noopener noreferrer">{item.type_of_ad}</a></span>
                      </div>
                    </> : <>
                      <div className="col-md-12 col-sm-12" style={{ marginBottom: 'calc(0.3vh + 0.3vw) !important', textAlign: 'left', fontSize: 'calc(0.5vw + 0.5vh)', display: 'flex' }}>
                        <span>Sourced :</span>&nbsp;<span><a href={item.source_url} target="_blank" title={item.source_name} className='ellipsis' rel="noopener noreferrer">{item.source_name}</a></span>
                      </div>
                      <div className="col-md-12 col-sm-12" style={{ marginBottom: 'calc(0.3vh + 0.3vw) !important', textAlign: 'left', fontSize: 'calc(0.5vw + 0.5vh)' }}>
                        {formatTimeAgo(new Date(item.published_date))}
                      </div>
                    </>}

                  </div>
                </div>
                <div className="col-md-5 col-sm-5 col-5 audio" style={{ marginBottom: 'calc(0.3vh + 0.3vw) !important', textAlign: 'right' }}>
                  {!isPlaying && !isPaused && (
                    <button style={{ border: 'none', background: 'none', fontSize: 'calc(0.9vw + 0.9vh)' }} onClick={() => startSpeech(index)}><img className='button-img-icon' src={play} /></button>
                  )}
                  {isPlaying && !isPaused && (
                    <button style={{ border: 'none', background: 'none', fontSize: 'calc(0.9vw + 0.9vh)', marginRight: 4 }} onClick={pauseSpeech}><img className='button-img-icon' src={pause} /></button>
                  )}
                  {isPaused && (
                    <button style={{ border: 'none', background: 'none', fontSize: 'calc(0.9vw + 0.9vh)', marginRight: 4 }} onClick={resumeSpeech}> <img className='button-img-icon' src={play} /></button>
                  )}
                  {isPlaying && (
                    <button style={{ border: 'none', background: 'none', fontSize: 'calc(0.9vw + 0.9vh)' }} onClick={stopSpeech}><img className='button-img-icon' src={stop} /></button>
                  )}
                </div>
                <div className="col-md-5 col-sm-5 col-5 audioshow" style={{ marginBottom: 'calc(0.3vh + 0.3vw) !important', textAlign: 'right' }}>
                  <button style={{ border: 'none', background: 'none', fontSize: 'calc(0.9vw + 0.9vh)' }} onClick={() => startSpeech(index)}><img className='button-img-icon' src={play} /></button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </>
      }

    </>
  }

  return (
    <div style={{ padding: '1vw 0vw' }}>
      <style>
        {`

@media only screen and (min-width: 600px) { 
  .swiper-button-prev, .swiper-rtl .swiper-button-next {
    left: var(--swiper-navigation-sides-offset, ${isMobile ? '92vw' : os == 'Windows' ? '65.2%' : (100 - 35) + '%'}) !important;
    right: auto;
    background: #00D172;
    padding:calc(1vw + 1vh) !important;
    border-radius: 50%;
    color: white;
  }
  .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
    font-size: calc(1vw + 1vh) !important;
    font-weight: 900;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
  }
  .swiper-slide-prev {
    background:none;
  }
  .swiper-slide-next {
    background:none;
  }

  .swiper-slide-active .row{
    // margin-left:0px !important ;
  }
  .swiper-button-next, .swiper-rtl .swiper-button-prev {
    right: var(--swiper-navigation-sides-offset,  ${isMobile ? '92vw' : os == 'Windows' ? '65.8%' : (100 - 34) + '%'}) !important;
    left: auto;
    pointer-events: ${isNextDisabled ? 'none' : 'auto'} !important;
    background:${isNextDisabled ? '#e8e8e8' : '#00D172'} !important;
    cursor:${isNextDisabled ? 'none' : 'pointer'} !important;
    padding: calc(1vw + 1vh) !important;
    border-radius: 50%;
    color: white;
  }
  .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
    font-size: calc(1vw + 1vh) !important;
    font-weight: 900;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
  }
  .swiper-cube-shadow {
    background:none !important;
  }
  .swiper-button-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  .swiper-slide {
    height:85vh !important;
    min-width: 25vh !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .css-18y2uza-MuiTypography-root-MuiTimelineContent-root{font-size:calc(0.55vw + 0.5vh);line-height: initial;padding:calc(0.1vw + 0vh) 0px calc(0.1vw + 0.3vh) calc(0.4vw + 0.4vh) !important;}
  .css-v6mo1y-MuiTimelineDot-root {
    padding:calc(0.1vh + 0.1vw) !important;    
    margin: calc(0.1vh + 0.1vw) 0 !important;
    border-width:calc(0.1vh + 0.1vw) !important;
    background-color: #bdbdbd;
  }
  .css-1ms7hib-MuiTimelineItem-root {
    min-height:10px !important;
  }
  .css-1ybubnw-MuiTimeline-root {
    padding:0px calc(0.2vw + 0.2vh) !important;
    margin: calc(0.2vw + 0.2vh) 0px !important;
  }
  .swiper-slide-prev {
    transition: none !important;
  } 
  .swiper-slide-next {
    transition: none !important;
  } 
  .swiper-slide-active {
    height:81vh !important;
    transition: transform 1s ease-in-out, z-index 1s ease-in-out !important;
    margin-right: calc(5vh + 5vw) !important;
  }
  .swiper-wrapper {
    transition: transform 1s ease-in-out, z-index 1s ease-in-out !important;
    display: flex;
    align-items: center;
  }
  .swiper-slide-prev .flip-card {
    height: ${isMobile ? '90vh' : '75vh'};
    margin-top : 5vh;
  }
  .swiper-slide-next .flip-card {
    height: ${isMobile ? '90vh' : '75vh'};
    margin-top : 5vh;
  }
  .swiper-slide-active .flip-card {
    height:  ${isMobile ? '90vh' : '80vh'};
  }
  .css-1ybubnw-MuiTimeline-root .MuiTimelineItem-root:before {
    min-height: 20px !important;
  }
  .css-1ami7lv-MuiTimelineConnector-root{
    width: 0px;
    background-color: transparent !important;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    border-style: dashed !important;
    border-color: #D0D6DA !important;
    border: 1px dashed #D0D6DA !important;
  }
  .css-wflvt-MuiTimeline-root {
    margin-bottom: 0px !important;
    padding: 6px 0px !important;
  }

  .swiper-button-prev, .swiper-button-next {
    width: calc(1.2vw + 1.2vh) !important;
    height: calc(1.2vw + 1.2vh) !important;
  }
  .swiper-slide-next, .swiper-slide-prev  .header {
    font-size: 'calc(0.6vw + 0.6vh)';
    min-height: calc(3vh + 3vw) !important;
    max-height: calc(3vh + 3vw) !important;
  }
  .swiper-slide-active .header {
    font-size: ${getHeaderFontSizeMobile(activeHeader)};
    min-height: calc(3vh + 3vw) ;
    max-height: calc(3vh + 3vw);
  }

  .swiper-slide-active .css-18y2uza-MuiTypography-root-MuiTimelineContent-root {
    font-size: ${getDescriptionFontSize(activeDescription)} ;
    min-height: calc(2.1vh + 2.1vw) !important; 
  }
  .swiper-slide-next .css-18y2uza-MuiTypography-root-MuiTimelineContent-root {
    min-height: calc(2.1vh + 2.1vw) !important; 
  }
  .swiper-slide-prev .css-18y2uza-MuiTypography-root-MuiTimelineContent-root {
    min-height: calc(2.1vh + 2.1vw) !important; 
  }
  .swiper-slide-active .image img {
    height: calc(8vw + 8vh);
  }

  .swiper-slide-active .cardDesign .audio{
    display: block;
  }
  .swiper-slide-prev .cardDesign .audio{
    display:none;
    min-height: calc(1vh + 1vh);
  }
  .swiper-slide-next .cardDesign .audio{
    display: none;
    min-height: calc(1vh + 1vh);
  }
  .swiper-slide-active .cardDesign .audioshow{
    display: none;
  }
  .swiper-slide-prev .cardDesign .audioshow{
    display:block;
    min-height: calc(1vh + 1vh);
  }
  .swiper-slide-next .cardDesign .audioshow{
    display: block;
    min-height: calc(1vh + 1vh);
  }
}


@media only screen and (max-width: 600px) { 

  .swiper-slide-active .cardDesign {
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    max-width: 80vw !important;
    min-width: 80vw !important;
    // min-height: 80vh !important;
    max-height: 80vh !important;
    margin-left: 10vw !important;
  }
  .swiper-slide-active .cardDesign .image{
    max-height:30vh !important
  }
  .swiper-slide-active .cardDesign .image img{ 
    height:100% !important;
  }

  .swiper-slide-active .cardDesign .description{ 
    max-height:none !important;
    padding: 0px 0px !important; 
  }

  .swiper-slide-active .header {
    font-size: 14px !important;
    min-height: calc(6vh + 6vw) ;
    max-height: calc(6vh + 6vw);
  }

  .swiper-slide-active .css-18y2uza-MuiTypography-root-MuiTimelineContent-root {
    font-size: 12px !important ;
    min-height: calc(2.1vh + 2.1vw) !important; 
  }

  .css-v6mo1y-MuiTimelineDot-root {
    padding: calc(0.3vh + 0.3vw) !important;
    margin: calc(0.1vh + 0.1vw) 0 !important;
    border-width: calc(0.1vh + 0.1vw) !important;
    background-color: #bdbdbd;
  }

  .css-1ami7lv-MuiTimelineConnector-root {
    width: 0px;
    background-color: transparent !important;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    border-style: dashed !important;
    border-color: #D0D6DA !important;
    border: 1px dashed #D0D6DA !important;
}

.css-1ms7hib-MuiTimelineItem-root {
  list-style: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  min-height: 40px !important;
}

.swiper-button-prev, .swiper-rtl .swiper-button-next {
  left: var(--swiper-navigation-sides-offset, ${isMobile ? '90vw' : os == 'Windows' ? '65.2%' : (100 - 35) + '%'}) !important;
  right: auto;
  background: #00D172;
  padding:calc(1vw + 1vh) !important;
  border-radius: 50%;
  color: white;
}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  font-size: calc(1vw + 1vh) !important;
  font-weight: 900;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}
.swiper-slide-prev {
  background:none;
}
.swiper-slide-next {
  background:none;
}

.swiper-slide-active .row{
  // margin-left:0px !important ;
}
.swiper-button-next, .swiper-rtl .swiper-button-prev {
  right: var(--swiper-navigation-sides-offset,  ${isMobile ? '90vw' : os == 'Windows' ? '65.8%' : (100 - 34) + '%'}) !important;
  left: auto;
  pointer-events: ${isNextDisabled ? 'none' : 'auto'} !important;
  background:${isNextDisabled ? '#e8e8e8' : '#00D172'} !important;
  cursor:${isNextDisabled ? 'none' : 'pointer'} !important;
  padding: calc(1vw + 1vh) !important;
  border-radius: 50%;
  color: white;
}
.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  font-size: calc(1vw + 1vh) !important;
  font-weight: 900;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}

.swiper-button-prev, .swiper-button-next {
  width: calc(3vw + 3vh) !important;
  height: calc(3vw + 3vh) !important;
}

.swiper-slide-active .cardDesign {
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  max-width: 80vw !important;
  min-width: 80vw !important;
  max-height: 88vh !important;
  margin-left: 10vw !important;
}


.css-18y2uza-MuiTypography-root-MuiTimelineContent-root {
  padding:0px 15px
}

.cardDesign .description ul {
  margin-bottom: 0.1rem !important;
}
.cardDesign {
  padding: 0;
}

.cardDesign .image img {
  width: 100%;
  height: auto; /* Adjusts height for better image aspect ratio */
}

.cardDesign .header {
  font-size: calc(1.3vw + 1.3vh) !important;
  padding: 8px 12px;
  min-height: calc(6vh + 6vw);
  max-height: calc(6vh + 6vw);
}

.cardDesign .description {
  background: #F8F9FC;
  min-height: calc(10vw + 10vh);
  max-height: calc(10vw + 10vh);
  padding: 8px 12px;
}


.cardDesign .audio button,
.cardDesign .audioshow button {
  font-size: calc(1.2vw + 1.2vh);
}

.cardDesign .audio,
.cardDesign .audioshow {
  text-align: right;
  padding-right: 8px;
}

.cardDesign .header,
.cardDesign .description,
.cardDesign .audio,
.cardDesign .audioshow {
  line-height: 1.2;
  text-align: left;
}

/* Adjust source and time elements */
.cardDesign .source-info {
  font-size: calc(0.8vw + 0.8vh);
  text-align: left;
}

.cardDesign .source-info a {
  color: #007BFF; /* Custom link color for mobile */
  text-decoration: underline;
}

.cardDesign .source-info span {
  display: inline-block;
  margin-right: 5px;
}


.swiper-slide-active .cardDesign .audio{
  display: block;
}
.swiper-slide-prev .cardDesign .audio{
  display:none;
  min-height: calc(1vh + 1vh);
}
.swiper-slide-next .cardDesign .audio{
  display: none;
  min-height: calc(1vh + 1vh);
}
.swiper-slide-active .cardDesign .audioshow{
  display: none;
}
.swiper-slide-prev .cardDesign .audioshow{
  display:block;
  min-height: calc(1vh + 1vh);
}
.swiper-slide-next .cardDesign .audioshow{
  display: block;
  min-height: calc(1vh + 1vh);
}
.button-img-icon {
  height:calc(4vh + 4vw)
}

.cardDesign .row > div {
  margin: 1px 0 ;
  font-size: 12px !important;
}


}
          
          
        `}
      </style>
      <Swiper
        slidesPerView={isMobile ? 1 : Math.min(3, data.length)}
        effect={'coverflow'}
        grabCursor={false}
        centeredSlides={true}
        spaceBetween={spaceBetween}
        coverflowEffect={{
          rotate: 30,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        }}
        pagination={true}
        navigation={true}
        modules={[EffectCoverflow, Navigation]}
        // modules={[Navigation]}
        className="mySwiper"
        onSwiper={setSwiperRef}
        onSlideChange={handleSlideChange}
      >

        {data.map((item, index) => (

          <SwiperSlide key={index}>
            <div
              style={{
                width: isMobile ? '100%' : '100%',
                border: "1 solid white",
                textAlign: "center",
                borderRadius: "10px",
                padding: "0px",
                backgroundColor: "white"
              }}
            >
              {
                item.ad_type == "Full Scren Media" ?
                  <>
                    {item.media_url ? <a href={item.source_url} target="_blank" rel="noopener noreferrer"><img src={item.media_url} width={"100%"} style={{ height: 'calc(26vw + 26vh)' }} alt={item.title} /></a> : ''}
                  </> : item.default ?
                    <>
                      <div className="row cardDesign1">
                        <img src={defaultImage} width={"100%"} style={{ height: 'calc(25vh + 25vw)', padding: 0 }} alt={item.title} />
                      </div>

                    </> :
                    <>
                      {cardDesign(item, index)}
                    </>
              }
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
