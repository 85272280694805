import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="notfound-container">
      <div className='row'>
        <div className='col-12'>
          <h2>Error, Page Not Found</h2>
        </div>
        <div className='col-12'>
          <h4><Link to="/">Go to Home</Link></h4>
        </div>
      </div>
    </div>
  );
};

export default NotFound;